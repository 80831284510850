import { DateTime } from 'luxon';

export const formatDecimal = (value: number, decimals: number): string => {
  return (Math.round(value * 100) / 100).toFixed(decimals);
};

export const getCSSVariable = (name: string) => {
  return window
    .getComputedStyle(document.documentElement)
    .getPropertyValue(`${name}`);
};

export const parseJwt = (token: string): any => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join(''),
  );

  return JSON.parse(jsonPayload);
};

const units = ['year', 'month', 'week', 'day', 'hour', 'minute', 'second'];

export const timeAgo = (date: string, lang: string): string => {
  const input = DateTime.fromISO(date, { zone: 'utc' });
  const diff = input.diffNow();
  const shifted = diff.shiftTo(...(units as any));
  const unit = units.find(unit => shifted.get(unit as any) !== 0) || 'second';

  const relativeFormatter = new Intl.RelativeTimeFormat(lang, {
    numeric: 'auto',
  });
  return relativeFormatter.format(
    Math.trunc(shifted.as(unit as any)),
    unit as any,
  );
};
