const queryKeys = {
  appuser: {
    info: 'appuser.info',
  },
  producer: {
    availableLcas: 'producer.lca.available',
    lcaData: 'producer.lca.data',
    lcaForm: 'producer.lca.form',
    lcaTemplates: 'producer.lca.templates',
    mydata: 'producer.mydata',
  },
};

export default queryKeys;
