import * as R from 'ramda';
import React from 'react';
import {
  Button,
  CardBlock,
  DebouncedInput,
  Section,
  Select,
} from '@biocodeio/components';
import {
  InputParameterConfiguration,
  ListSectionConfiguration,
} from './LcaForm.types';
import { isVisible, getValueRangeError } from './formValidation';
import { useTranslation } from 'react-i18next';

const ListSection: React.FC<any> = ({ locked, values, section, setValues }) => {
  const { t, i18n } = useTranslation(['common', 'validation']);

  const localized_name_field = `name_${i18n.language}`;

  const listSection = section as ListSectionConfiguration;

  const getValuesForList = (values: any, listName: string) =>
    R.pathOr([], ['lists', listName, 'items'], values);

  return (
    <Section border key={section.id} wrapSize="xSmall">
      <h2>{(section as any)[localized_name_field]}</h2>
      <div className="grid">
        <div className="grid-col grid-col:6@m">
          <Select
            disabled={locked}
            id={section.id}
            onChange={(e: any) => {
              setValues((oldValues: any) => ({
                ...oldValues,
                lists: {
                  ...oldValues.lists,
                  [section.id]: {
                    items: [
                      {
                        id: e.value,
                        ...R.fromPairs(
                          listSection.item_parameters.map((ip: any) => [
                            ip.id,
                            '',
                          ]),
                        ),
                      },
                      ...getValuesForList(oldValues, section.id),
                    ],
                  },
                },
              }));
            }}
            options={listSection.items
              .filter(
                (item: any) =>
                  getValuesForList(values, section.id).find(
                    (added: any) => added.id === item.id,
                  ) === undefined,
              )
              .map((i: any) => ({
                label: i[localized_name_field],
                value: i.id,
              }))
              .sort((a: any, b: any) => a.label.localeCompare(b.label))}
          />
        </div>
      </div>

      {getValuesForList(values, section.id).map((item: any, i: number) => {
        const itemConfig = listSection.items.find(
          (i: any) => i.id === item.id,
        ) as InputParameterConfiguration;
        return (
          <CardBlock
            heading={(itemConfig as any)[localized_name_field]}
            isDisabled={locked}
            key={itemConfig.id}
            size="small"
            toolbarButtons={[
              <Button
                disabled={locked}
                key="button"
                onClick={() => {
                  const updatedList = getValuesForList(
                    values,
                    section.id,
                  ).filter((li: any) => li.id !== itemConfig.id);
                  setValues((oldValues: any) => ({
                    ...oldValues,
                    lists: {
                      ...oldValues.lists,
                      [section.id]: { items: updatedList },
                    },
                  }));
                }}
                outline="strong"
                size="small"
              >
                {t('delete')}
              </Button>,
            ]}
          >
            <div className="grid">
              {listSection.item_parameters.map((ip: any) => {
                const depsOk = isVisible(ip.id, values, ip.dependencies);
                let error = undefined;
                if (depsOk.isValid) {
                  error = getValueRangeError(ip, item[ip.id], t);
                }
                const missingMandatory = ip.required && !item[ip.id];
                const missingText = t('validation:warning.formFieldMandatory');

                // if param depencies do not pass, rendering of input is skipped
                if (!depsOk.isValid) return null;

                let input = null;

                if (ip.type === 'select') {
                  const options =
                    ip.options?.map((x: any) => {
                      return {
                        label: x[localized_name_field],
                        value: x.value,
                      };
                    }) || [];

                  input = (
                    <div
                      className="grid-col grid-col:6@m grid-col:4@l"
                      key={ip.id}
                    >
                      <Select
                        disabled={locked}
                        id={`${item.id}-${ip.id}`}
                        isWarning={missingMandatory ? true : false}
                        key={`${item.id}-${ip.id}`}
                        label={ip[localized_name_field]}
                        labelSuffix={ip.required ? '*' : undefined}
                        onChange={(selected: any) => {
                          setValues((oldValues: any) => {
                            const updatedList = R.clone(
                              getValuesForList(oldValues, section.id),
                            ).map((item: any) =>
                              item.id === itemConfig.id
                                ? { ...item, [ip.id]: selected?.value }
                                : item,
                            );
                            return {
                              ...oldValues,
                              lists: {
                                ...oldValues.lists,
                                [section.id]: { items: updatedList },
                              },
                            };
                          });
                        }}
                        options={options}
                        placeholder={t('common:select')}
                        value={options.find(
                          (x: any) => x.value === item[ip.id],
                        )}
                        warning={missingText}
                      />
                    </div>
                  );
                } else {
                  input = (
                    <div
                      className="grid-col grid-col:6@m grid-col:4@l"
                      key={`${ip.id}-${i}`}
                    >
                      <DebouncedInput
                        debounceTime={20}
                        disabled={locked}
                        error={error}
                        id={`${item.id}-${ip.id}`}
                        initialValue={item[ip.id] || ''}
                        isError={error ? true : false}
                        isWarning={missingMandatory ? true : false}
                        key={`${item.id}-${ip.id}`}
                        label={ip[localized_name_field]}
                        labelSuffix={ip.required ? '*' : undefined}
                        onChange={(value: any) => {
                          setValues((oldValues: any) => {
                            const updatedList = R.clone(
                              getValuesForList(oldValues, section.id),
                            ).map((item: any) =>
                              item.id === itemConfig.id
                                ? { ...item, [ip.id]: value }
                                : item,
                            );
                            return {
                              ...oldValues,
                              lists: {
                                ...oldValues.lists,
                                [section.id]: { items: updatedList },
                              },
                            };
                          });
                        }}
                        required={item.required ? true : false}
                        unit={t(`units:${ip.unit}`)}
                        warning={missingText}
                      />
                    </div>
                  );
                }
                return input;
              })}
            </div>
          </CardBlock>
        );
      })}
    </Section>
  );
};

export default ListSection;
