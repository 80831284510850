import { Action, ThunkAction, configureStore } from '@reduxjs/toolkit';

import authReducer from '../app/authSlice';
import headerReducer from '../components/Header/headerSlice';
import { lcaInfoReducer } from '../components/Sidebar';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    header: headerReducer,
    lcaInfo: lcaInfoReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
