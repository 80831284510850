import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { RootState } from '../../app/store';

export interface HeaderState {
  modifier: 'default' | 'transparent' | 'plain';
}

const initialState: HeaderState = {
  modifier: 'default',
};

const headerSlice = createSlice({
  initialState,
  name: 'header',
  reducers: {
    changeHeaderModifier: (
      state,
      action: PayloadAction<HeaderState['modifier']>,
    ) => {
      state.modifier = action.payload;
    },
  },
});

export const { changeHeaderModifier } = headerSlice.actions;
export const selectHeaderModifier = (
  state: RootState,
): HeaderState['modifier'] => state.header.modifier;
export default headerSlice.reducer;
