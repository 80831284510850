import React, { ReactElement } from 'react';
import Views from './views/Views';
import { Loader } from '@biocodeio/components';
import { QueryClient, QueryClientProvider } from 'react-query';
import { useAccessToken } from './components/Auth0/useAccessToken';
import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';

function App(): ReactElement {
  const { isLoading } = useAuth0();
  const { ready } = useTranslation(['common', 'validation'], {
    useSuspense: false,
  });
  const { isLoading: accessTokenIsLoading } = useAccessToken();
  const queryClient = new QueryClient();

  if (isLoading || !ready || accessTokenIsLoading) {
    return (
      <div className="App-loader">
        <Loader />
      </div>
    );
  }

  return (
    <div className="App">
      <QueryClientProvider client={queryClient}>
        <Views />
      </QueryClientProvider>
    </div>
  );
}

export default App;
