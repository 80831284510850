import Auth from '../components/Auth';
import PrivateRoute from '../components/Auth0/PrivateRoute';
import React, { Fragment, ReactElement } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

import Header from '../components/Header';
import Home from './Home';
import LcaForm from './LcaForm';

export default function Views(): ReactElement {
  const { error, isAuthenticated } = useAuth0();

  return (
    <Fragment>
      <Header modifier={isAuthenticated ? 'default' : 'plain'} />
      <Switch>
        {isAuthenticated ? (
          <PrivateRoute component={Home} exact path="/" />
        ) : (
          <Route
            exact
            path="/"
            render={props => <Auth error={error} {...props} />}
          />
        )}
        <PrivateRoute component={LcaForm} exact path="/entry/:id/:lcaId" />
      </Switch>
    </Fragment>
  );
}
