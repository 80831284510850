import React, { FC, MouseEvent } from 'react';
import { Dropdown } from '@biocodeio/components';
import { HeaderState, selectHeaderModifier } from './headerSlice';
import { shallowEqual, useSelector } from 'react-redux';
import { UserProfile, selectUserProfile } from '../../app/authSlice';

import AppUser from '../AppUser';
import c from 'classnames';
import { Link } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface HeaderProps {
  modifier?: HeaderState['modifier'];
}

const Header: FC<HeaderProps> = ({ modifier }) => {
  const { isAuthenticated, logout } = useAuth0();
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const modifierSelector = useSelector(selectHeaderModifier, shallowEqual);
  const transparent =
    modifierSelector === 'transparent' || modifier === 'transparent';
  const plain = modifierSelector === 'plain' || modifier === 'plain';
  const changeLng = (lng: string) => {
    i18n.changeLanguage(lng).then(() => window.location.reload());
  };
  const userProfile = useSelector(selectUserProfile);

  let logoutReturnUrl: string;
  let logo;
  if (userProfile === UserProfile.admin) {
    logoutReturnUrl =
      process.env.REACT_APP_PRODUCT_CLIENT_HOST || '/not-defined';
    logo = (
      <a className="Header-logo" href={logoutReturnUrl}>
        Biocode
      </a>
    );
  } else {
    logoutReturnUrl = window.location.origin;
    logo = (
      <Link className="Header-logo" to="/">
        Biocode
      </Link>
    );
  }

  return (
    <div
      className={c('Header', {
        'is-plain': plain,
        'is-transparent': transparent,
      })}
    >
      <div className="Header-wrap wrap">
        {logo}
        {isAuthenticated && (
          <nav className="Header-nav">
            <ul>
              <li className="Header-nav-item">
                <Link className="Header-nav-link" to="/">
                  {t('calculations.all')}
                </Link>
              </li>
              <li className="Header-nav-item Header-nav-item--menu">
                <Dropdown
                  align="right"
                  buttonStyle="plain"
                  buttonText={t('menu')}
                  icon="hamburger"
                >
                  {!plain && (
                    <Dropdown.Item>
                      <AppUser showOrganization={false} />
                    </Dropdown.Item>
                  )}
                  <Dropdown.Item
                    className="Header-nav-item-dropdown-item"
                    href="/"
                    modifier="separator"
                    onClick={(e: MouseEvent) => {
                      e.preventDefault();
                      history.push('/');
                    }}
                    type="link"
                  >
                    {t('calculations.all')}
                  </Dropdown.Item>
                  <Dropdown.Heading
                    heading={t('lngChange')}
                    modifier="separator"
                  />
                  <Dropdown.Item onClick={() => changeLng('en')} type="button">
                    {t('lngEn')}
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => changeLng('fi')} type="button">
                    {t('lngFi')}
                  </Dropdown.Item>
                  <Dropdown.Item
                    modifier="separator"
                    onClick={() => logout({ returnTo: logoutReturnUrl })}
                    type="button"
                  >
                    {t('logout')}
                  </Dropdown.Item>
                </Dropdown>
              </li>
            </ul>
          </nav>
        )}
      </div>
    </div>
  );
};

export default Header;
