import producerLcaClient from '../ProducerLca/producerLcaClient';
import React, { MouseEvent, FC, useMemo } from 'react';
import { Button, Card, Table } from '@biocodeio/components';
import {
  LcaInfo,
  LcaInfoResponse,
  LcaResultStatus,
} from '../ProducerLca/ProducerLca.types';
import { LcaListProps } from './LcaListing.types';
import { default as q } from '../../app/queryKeys';
import { useHistory } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';

const Cards: FC<LcaListProps> = ({ data, status }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const createLca = useMutation(
    async (lcaSettingsId: string) => {
      return await producerLcaClient.createProducerLca(lcaSettingsId);
    },
    {
      onSuccess: (data, variables) => {
        history.push(`/entry/${variables}/${data}`);
      },
    },
  );

  const cards = Object.entries(data[status]).map(
    (lcaInfoArr: [string, LcaInfo[]]) => {
      const lcaInfos: LcaInfo[] = lcaInfoArr[1];
      const cards = lcaInfos.map((lcaInfo, i) => {
        return (
          <div className="grid-col grid-col:6@m grid-col:4@l" key={i}>
            {!lcaInfo.producerLcaId ? (
              <Card
                buttonText={t('start')}
                heading={lcaInfoArr[0]}
                href="#"
                name={lcaInfoArr[0]}
                onClick={(e: MouseEvent) => {
                  e.preventDefault();
                  createLca.mutate(lcaInfo.lcaSettingsId);
                }}
                subtitle={lcaInfo.year}
              />
            ) : (
              <Card
                buttonText={t('open')}
                heading={lcaInfoArr[0]}
                href={`/entry/${lcaInfo.lcaSettingsId}/${lcaInfo.producerLcaId}`}
                name={lcaInfoArr[0]}
                onClick={(e: MouseEvent) => {
                  e.preventDefault();
                  history.push(
                    `/entry/${lcaInfo.lcaSettingsId}/${lcaInfo.producerLcaId}`,
                  );
                }}
                subtitle={lcaInfo.year}
              />
            )}
          </div>
        );
      });

      return cards;
    },
  );

  if (!cards || !cards.length) {
    return <p>{t('lcaListing.available.none')}</p>;
  }

  return <div className="grid marginBottom--l -rowGap">{cards}</div>;
};

const StatusTable: FC<LcaListProps> = ({ data, status }) => {
  const lcaStatuses = Object.entries(data[status]);
  const { t } = useTranslation();
  const history = useHistory();
  const createLca = useMutation(
    async (lcaSettingsId: string) => {
      return await producerLcaClient.createProducerLca(lcaSettingsId);
    },
    {
      onSuccess: (data, variables) => {
        history.push(`/entry/${variables}/${data}`);
      },
    },
  );

  const memoColumns = useMemo(
    () => [
      {
        accessor: 'year',
        header: t('year'),
      },
      {
        Cell: function Cell(rowData: any) {
          const { producerLcaId, lcaSettingsId } = rowData.row.original;

          const action =
            producerLcaId === null ? (
              <Button
                onClick={(e: MouseEvent) => {
                  e.preventDefault();
                  createLca.mutate(lcaSettingsId);
                }}
                size="small"
              >
                {t('start')}
              </Button>
            ) : (
              <Button
                href={`/entry/${lcaSettingsId}/${producerLcaId}`}
                onClick={(e: MouseEvent) => {
                  e.preventDefault();
                  history.push(`/entry/${lcaSettingsId}/${producerLcaId}`);
                }}
                size="small"
              >
                {t('open')}
              </Button>
            );

          return <>{action}</>;
        },
        accessor: 'action',
        disableSortBy: true,
        header: '',
      },
    ],
    [createLca, history, t],
  );

  const TableComponent = ({ lcaData }: { lcaData: [string, LcaInfo[]] }) => {
    const heading = lcaData[0];
    const lcaInfos: LcaInfo[] = lcaData[1];
    const memoLcaInfos = useMemo(() => lcaInfos, [lcaInfos]);
    const memoSort = useMemo(
      () => [
        {
          desc: true,
          id: 'year',
        },
      ],
      [],
    );

    return (
      <>
        <h3>{heading}</h3>
        <Table
          columns={memoColumns}
          data={memoLcaInfos}
          sort={true}
          tableOptions={{
            initialState: {
              sortBy: memoSort,
            },
          }}
        />
      </>
    );
  };

  if (lcaStatuses.length === 0) {
    return <span>{t('lcaListing.available.none')}</span>;
  }

  return (
    <>
      {lcaStatuses.map((lca: any, i: any) => {
        return <TableComponent key={i} lcaData={lca} />;
      })}
    </>
  );
};

const LcaListing: FC = () => {
  const { data, isLoading } = useQuery<LcaInfoResponse>(
    q.producer.availableLcas,
    async (): Promise<any> => await producerLcaClient.getProducerLcas(),
    {
      onSuccess: (data: any) => {
        return data;
      },
    },
  );
  const { t } = useTranslation();

  if (isLoading || !data) {
    return <span>{t('loading')}</span>;
  }

  return (
    <>
      <h2>{t('lcaListing.available.header')}</h2>
      <Cards data={data} status={LcaResultStatus.Available} />
      <h2>{t('lcaListing.completed.header')}</h2>
      <StatusTable data={data} status={LcaResultStatus.Completed} />
    </>
  );
};

export default LcaListing;
