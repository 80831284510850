import appUserClient from './appUserClient';
import q from '../../app/queryKeys';
import React, { FC, Fragment } from 'react';
import { AppUserProps } from './AppUser.types';
import { Avatar } from '@biocodeio/components';
import { useAuth0 } from '@auth0/auth0-react';
import { useQuery } from 'react-query';

const AppUser: FC<AppUserProps> = ({ showOrganization = true }) => {
  const { user } = useAuth0();
  const { data } = useQuery(
    q.appuser.info,
    async (): Promise<any> => {
      return await appUserClient.getInfo();
    },
    {
      enabled: showOrganization,
    },
  );

  return (
    <Fragment>
      {data && <span className="Header-nav-label">{data?.org?.name}</span>}
      <Avatar
        email={user?.email}
        nick={user?.nickname || ''}
        picture={user?.picture}
      />
    </Fragment>
  );
};

export { AppUser };
