import { DebouncedInput, Section, Select, Switch } from '@biocodeio/components';
import { isVisible, getValueRangeError } from './formValidation';
import {
  resetLcaInputInfo,
  selectLcaInputIsOpen,
  setLcaInputInfo,
} from '../Sidebar';
import { useDispatch, useSelector } from 'react-redux';

import React from 'react';
import ReactMarkdown from 'react-markdown';
import { SectionGroupConfiguration } from './LcaForm.types';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';

const NormalSection: React.FC<any> = ({
  locked,
  values,
  section,
  updateInputValue,
}) => {
  const { t, i18n } = useTranslation(['common', 'validation', 'units']);
  const localized_name_field = `name_${i18n.language}`;
  const localized_info_field = `info_${i18n.language}`;
  const dispatch = useDispatch();
  const isInfoOpen = useSelector(selectLcaInputIsOpen);
  const history = useHistory();

  const groups =
    section.groups &&
    section.groups
      .map((sectionGroup: SectionGroupConfiguration, i: any) => {
        const parameters =
          sectionGroup.parameters &&
          sectionGroup.parameters
            .map((param: any, i: any) => {
              const visibiility = isVisible(
                param.id,
                values,
                param.dependencies,
              );
              // if param depencies do not pass, rendering of input is skipped
              if (!visibiility.isValid) return null;

              let input = null;
              if (param.type === 'boolean') {
                input = (
                  <div className="grid-col grid-col:6@m -ruler" key={param.id}>
                    <Switch
                      checked={values.inputs[param.id] || false}
                      disabled={locked}
                      id={param.id}
                      label={param[localized_name_field]}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        updateInputValue({
                          name: param.id,
                          value: e.target.checked,
                        });
                      }}
                    />
                  </div>
                );
              } else if (param.type === 'string') {
                const missingMandatory =
                  param.required && !values.inputs[param.id];
                const missingText = t('validation:warning.formFieldMandatory');
                input = (
                  <div className="grid-col grid-col:6@m" key={param.id}>
                    <DebouncedInput
                      debounceTime={20}
                      disabled={locked}
                      id={param.id}
                      info={param[localized_info_field]}
                      infoOnClick={() => {
                        if (!isInfoOpen) {
                          setLcaInputInfo(
                            {
                              id: param.id,
                              info: param[localized_info_field],
                              name: param[localized_name_field],
                            },
                            dispatch,
                            history,
                          );
                        } else {
                          resetLcaInputInfo(dispatch, history);
                        }
                      }}
                      initialValue={values.inputs[param.id] || ''}
                      isWarning={missingMandatory ? true : false}
                      label={param[localized_name_field]}
                      labelSuffix={param.required ? '*' : undefined}
                      onChange={(value: any) => {
                        updateInputValue({
                          name: param.id,
                          value: value,
                        });
                      }}
                      required={param.required ? true : false}
                      type="text"
                      warning={missingText}
                    />
                  </div>
                );
              } else if (param.type === 'select') {
                const options =
                  param.options?.map((x: any) => {
                    return { label: x[localized_name_field], value: x.value };
                  }) || [];

                const selectedOption = options.find(
                  (x: any) =>
                    x.value === values.inputs[param.id] ||
                    x.value.toString() === values.inputs[param.id],
                );

                const missingMandatory =
                  param.required && !values.inputs[param.id];
                const missingText = t('validation:warning.formFieldMandatory');

                input = (
                  <div className="grid-col grid-col:6@m" key={param.id}>
                    <Select
                      disabled={locked}
                      id={param.id}
                      info={param.hasOwnProperty(localized_info_field)}
                      infoOnClick={() => {
                        if (!isInfoOpen) {
                          setLcaInputInfo(
                            {
                              id: param.id,
                              info: param[localized_info_field],
                              name: param[localized_name_field],
                            },
                            dispatch,
                            history,
                          );
                        } else {
                          resetLcaInputInfo(dispatch, history);
                        }
                      }}
                      isWarning={missingMandatory ? true : false}
                      label={param[localized_name_field]}
                      labelSuffix={param.required ? '*' : undefined}
                      onChange={(selected: any) => {
                        updateInputValue({
                          name: param.id,
                          value: selected?.value,
                        });
                      }}
                      options={options}
                      placeholder={t('select')}
                      value={selectedOption}
                      warning={missingText}
                    />
                  </div>
                );
              } else {
                const error = getValueRangeError(
                  param,
                  values.inputs[param.id],
                  t,
                );
                const missingMandatory =
                  param.required && !values.inputs[param.id];
                const missingText = t('validation:warning.formFieldMandatory');
                input = (
                  <div className="grid-col grid-col:6@m" key={param.id}>
                    <DebouncedInput
                      debounceTime={20}
                      disabled={locked}
                      error={error}
                      id={param.id}
                      info={param[localized_info_field]}
                      infoOnClick={() => {
                        if (!isInfoOpen) {
                          setLcaInputInfo(
                            {
                              id: param.id,
                              info: param[localized_info_field],
                              name: param[localized_name_field],
                            },
                            dispatch,
                            history,
                          );
                        } else {
                          resetLcaInputInfo(dispatch, history);
                        }
                      }}
                      initialValue={values.inputs[param.id] || ''}
                      isError={error ? true : false}
                      isWarning={missingMandatory ? true : false}
                      label={param[localized_name_field]}
                      labelSuffix={param.required ? '*' : undefined}
                      onChange={(value: any) => {
                        updateInputValue({
                          name: param.id,
                          value: value,
                        });
                      }}
                      required={param.required ? true : false}
                      unit={t(`units:${param.unit}`)}
                      warning={missingText}
                    />
                  </div>
                );
              }
              return input;
            })
            .filter((x: any) => x != null);

        return parameters && parameters.length > 0 ? (
          <div key={i}>
            {sectionGroup.top_level || (
              <h3 className="marginTop">
                {(sectionGroup as any)[localized_name_field]}
              </h3>
            )}
            <div className="grid">{parameters}</div>
          </div>
        ) : null;
      })
      .filter((x: any) => x != null);

  return groups && groups.length > 0 ? (
    <Section border wrapSize="xSmall">
      <h2>{section[localized_name_field]}</h2>
      {section[localized_info_field] && (
        <>
          <ReactMarkdown>{section[localized_info_field]}</ReactMarkdown>{' '}
        </>
      )}
      {groups}
    </Section>
  ) : null;
};

export default NormalSection;
