import i18n from '../../app/i18n/i18n';
import {
  AuthStatus,
  selectToken,
  setAuthStatus,
  setToken,
  setUserProfile,
  userProfileClaim,
} from '../../app/authSlice';
import { parseJwt } from '../../lib/utils';
import { setAxiosDefaults } from '../../lib/axios-interceptor';
import { UseAccessTokenType } from './Auth0.types';
import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';

export function useAccessToken(): UseAccessTokenType {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [firstLoadDone, setFirstLoadDone] = useState(false);
  const authToken = useSelector(selectToken);
  const [accessTokenIsLoading, setAccessTokenIsLoading] = useState(false);
  const dispatch = useDispatch();

  async function setApiAccessToken() {
    const currLang = i18n.language || 'fi';
    const token = await getAccessTokenSilently();
    if (token && authToken !== token) {
      const jwt = parseJwt(token);
      const userProfile = jwt[userProfileClaim];
      dispatch(setToken(token));
      dispatch(setAuthStatus(AuthStatus.Authenticated));
      setAxiosDefaults(token, currLang);
      if (userProfile) {
        dispatch(setUserProfile(userProfile));
      }
      setAccessTokenIsLoading(prev => false);
    }
  }

  if (
    !firstLoadDone &&
    isAuthenticated &&
    !authToken &&
    !accessTokenIsLoading
  ) {
    setAccessTokenIsLoading(true);
    setApiAccessToken();
    setFirstLoadDone(true);
  }

  return { isLoading: accessTokenIsLoading };
}
