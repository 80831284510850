import * as R from 'ramda';
import {
  InputParameterConfiguration,
  ListSectionConfiguration,
  ModelConfiguration,
  NormalSectionConfiguration,
  SectionConfiguration,
  SectionGroupConfiguration,
} from './LcaForm.types';
import { LCAFORM_CONSTANTS } from './LcaForm.constants';

export const getParameterLocalication = (
  id: string,
  configuration: ModelConfiguration,
  localizedKey: string,
): string | undefined => {
  for (const section of configuration.sections) {
    if (section.type === LCAFORM_CONSTANTS.type_normal) {
      const found = (section as NormalSectionConfiguration).groups
        .flatMap(g => g.parameters)
        .filter(p => p.id === id)
        .map(p => (p as any)[localizedKey]);
      if (found.length > 0) return found[0];
    }
    if (section.type === LCAFORM_CONSTANTS.type_list) {
      const found = (section as ListSectionConfiguration).item_parameters
        .filter(ip => ip.id === id)
        .map(ip => (ip as any)[localizedKey]);
      if (found.length > 0) return found[0];
    }
  }
  return undefined;
};

export const getDefaultsForNormalSections = (
  lcaConfiguration: ModelConfiguration,
): any => {
  const formSections = R.pathOr([], ['sections'], lcaConfiguration) as any[];

  const normalSections = formSections.filter(
    (section: SectionConfiguration) =>
      section.type === LCAFORM_CONSTANTS.type_normal,
  );
  const initialValuePairs = normalSections
    .flatMap((section: NormalSectionConfiguration) => section.groups)
    .flatMap((group: SectionGroupConfiguration) => group.parameters)
    .filter(
      (parameter: InputParameterConfiguration) =>
        !R.isNil(parameter.default_value),
    )
    .map((parameter: InputParameterConfiguration) => {
      const newObj = {} as any;
      newObj[parameter.id] = parameter.default_value;
      return newObj;
    });

  return R.mergeAll(initialValuePairs);
};
