import axios from 'axios';

const appUserClient = {
  getInfo: async (): Promise<any> => {
    try {
      const resp = await axios.get('/user/info-v1');
      return resp.data;
    } catch (e) {
      console.error(e);
      return [];
    }
  },
};

export default appUserClient;
