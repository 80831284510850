import React, { useEffect } from 'react';
import { AUTH0_APP_NAME } from '../../app/constants';
import { Loader } from '@biocodeio/components';
import { Route } from 'react-router-dom';
import { useAccessToken } from './useAccessToken';
import { useAuth0 } from '@auth0/auth0-react';

// typings are't exactly correct but working
const PrivateRoute = ({
  component: Component,
  options: WithAuthenticationRequiredOptions = {},
  ...rest
}: React.PropsWithChildren<any>): any => {
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
  const { isLoading: accessTokenIsloading } = useAccessToken();
  const defaultReturnTo = (): string =>
    `${window.location.pathname}${window.location.search}`;
  const {
    returnTo = defaultReturnTo,
    onRedirecting = <Loader />,
    loginOptions = {},
  } = WithAuthenticationRequiredOptions;

  const isLoading2 = isLoading || isAuthenticated || accessTokenIsloading;

  useEffect(() => {
    if (isLoading2) {
      return;
    }
    const opts = {
      ...loginOptions,
      app: AUTH0_APP_NAME,
      appState: {
        ...loginOptions.appState,
        returnTo: typeof returnTo === 'function' ? returnTo() : returnTo,
      },
    };
    (async (): Promise<void> => {
      await loginWithRedirect(opts);
    })();
  }, [
    isLoading,
    isLoading2,
    isAuthenticated,
    loginWithRedirect,
    returnTo,
    accessTokenIsloading,
    loginOptions,
  ]);

  return isAuthenticated && !accessTokenIsloading ? (
    <Route {...rest} render={props => <Component {...props} />} />
  ) : (
    onRedirecting
  );
};

export default PrivateRoute;
