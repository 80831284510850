import React, { ReactElement, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UserProfile, selectUserProfile } from '../../app/authSlice';

import LcaListing from '../../components/LcaListing/LcaListing';
import { changeHeaderModifier } from '../../components/Header/headerSlice';
import { URL_PATH } from '../../app/constants';
import { useTranslation } from 'react-i18next';
import { View } from '@biocodeio/components';

function Home(): ReactElement {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const userProfile = useSelector(selectUserProfile);

  useEffect(() => {
    dispatch(changeHeaderModifier('transparent'));

    return () => {
      dispatch(changeHeaderModifier('default'));
    };
  }, [dispatch]);

  // admins are redirected to product
  if (userProfile === UserProfile.admin) {
    window.location.href =
      (process.env.REACT_APP_PRODUCT_CLIENT_HOST || '/not-defined') +
        URL_PATH.producers || '/not-supported';
    // show isLoader while location changed
    return <View isLoading={true} modifier="home" />;
  } else {
    return (
      <View modifier="home">
        <header className="View-header">
          <div className="wrap">
            <h1 className="View-header-heading">{t('landing.welcome')}</h1>
            <div className="View-header-text">
              <p>{t('landing.description')}</p>
            </div>
          </div>
        </header>
        <section className="View-container container">
          <div className="wrap">
            <LcaListing />
          </div>
        </section>
      </View>
    );
  }
}

export default Home;
