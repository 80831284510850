import Backend from 'i18next-http-backend';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

i18n
  .use(Backend)
  .use(LanguageDetector) // detect user language
  .use(initReactI18next) // pass the i18n instance to react-i18next.
  // init i18next, for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    backend: {
      allowMultiLoading: true,
      loadPath: '/locales/{{lng}}/{{ns}}.json',
    },
    debug: false,
    defaultNS: 'common',
    detection: {
      // cache user language on
      caches: ['localStorage'],

      // ['localStorage', 'cookie']
      excludeCacheFor: ['cimode'],

      lookupCookie: 'culture',

      lookupFromPathIndex: 0,

      lookupFromSubdomainIndex: 0,

      lookupLocalStorage: 'culture',

      // keys or params to lookup language from
      lookupQuerystring: 'lng',

      lookupSessionStorage: 'culture',
      // order and from where user language should be detected
      order: [
        'querystring',
        //'cookie',
        'localStorage',
        'sessionStorage',
        'navigator',
        'htmlTag',
        'path',
        //'subdomain',
      ], // languages to not persist (cookie, localStorage)

      // optional expire and domain for set cookie
      // cookieMinutes: 10,
      // cookieDomain: 'myDomain',

      // optional htmlTag with lang attribute, the default is:
      // htmlTag: document.documentElement,

      // optional set cookie options, reference:[MDN Set-Cookie docs](https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie)
      // cookieOptions: { path: '/', sameSite: 'strict' },
    },
    fallbackLng: 'fi',
    fallbackNS: 'common',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    ns: ['common', 'units', 'validation'],
    react: {
      // bindI18n: 'languageChanged',
      // bindI18nStore: '',
      // transEmptyNodeValue: '',
      // transSupportBasicHtmlNodes: true,
      // transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
      useSuspense: false, // suspence is not in official react yet
    },
    supportedLngs: ['fi', 'en'],
  });

export default i18n;
