import React, { FC, Key, createRef } from 'react';
import {
  clearLcaInputInfo,
  resetLcaInputInfo,
  selectLcaInputInfo,
  selectLcaInputIsOpen,
} from './lcaInputInfoSlice';
import { Heading, Icon, useOutsideClick } from '@biocodeio/components';
import { useDispatch, useSelector } from 'react-redux';

import ReactMarkdown from 'react-markdown';
import { motion } from 'framer-motion';
import { sidebarTrans } from '../../../lib/animations';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';

interface SidebarLcaInputInfoProps {
  key: Key;
}

const SidebarLcaInputInfo: FC<SidebarLcaInputInfoProps> = () => {
  const { t } = useTranslation(['common', 'unit']);
  const dispatch = useDispatch();
  const ref = createRef<HTMLDivElement>();
  const info = useSelector(selectLcaInputInfo);
  const isInfoOpen = useSelector(selectLcaInputIsOpen);
  const history = useHistory();

  useOutsideClick(ref, () => {
    resetLcaInputInfo(dispatch, history);
  });

  return (
    <motion.div
      className="Sidebar Sidebar--lcaInputInfo"
      onAnimationComplete={() => {
        if (!isInfoOpen) dispatch(clearLcaInputInfo());
      }}
      ref={ref}
      {...sidebarTrans}
    >
      <header className="Sidebar-header">
        <Heading
          negative
          subtitle={t('info')}
          subtitleColor="strong"
          subtitleTruncate
          tag="h2"
        />
        <button
          className="Sidebar-header-close"
          onClick={() => {
            if (isInfoOpen) resetLcaInputInfo(dispatch, history);
          }}
        >
          <Icon name="close" />
        </button>
      </header>
      <div className="Sidebar-inner scrollbar -color:negative">
        {info?.name && (
          <section className="Sidebar-section">
            <h3 className="Sidebar-heading">{info?.name}</h3>
          </section>
        )}
        <section className="Sidebar-section">
          {info?.info && (
            <>
              <Heading negative subtitle={t('info')} />
              <p className="Sidebar-text">
                <ReactMarkdown>{info?.info}</ReactMarkdown>
              </p>
            </>
          )}
        </section>
      </div>
    </motion.div>
  );
};

export default SidebarLcaInputInfo;
