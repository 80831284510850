import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState, store } from '../../../app/store';

import { History } from 'history';

interface SidebarLcaInputInfoState {
  info?: InfoState;
  open: boolean;
}

interface InfoState {
  name: string;
  id: string;
  info: string;
}

const initialState: SidebarLcaInputInfoState = {
  open: false,
};

// TODO: Fetch input info from url and display it
// export const fetchLcaInputInfo = createAsyncThunk(
//   'lcaInfo/fetchInfo',
//   (id, thunkAPI) => {},
// );

export const setLcaInputInfo = (
  info: InfoState,
  dispatch: typeof store.dispatch,
  history: History,
): void => {
  dispatch(lcaInputInfoSlice.actions.setLcaInputInfo({ ...info }));
  history.push(`?info=true&id=${info.id}`);
};

export const resetLcaInputInfo = (
  dispatch: typeof store.dispatch,
  history: History,
): void => {
  dispatch(lcaInputInfoSlice.actions.resetLcaInputInfo());
  history.push(window.location.pathname);
};

const lcaInputInfoSlice = createSlice({
  initialState,
  name: 'lcaInfo',
  reducers: {
    clearLcaInputInfo: state => {
      state.info = undefined;
    },
    resetLcaInputInfo: state => {
      state.open = false;
    },
    setLcaInputInfo: (state, action: PayloadAction<InfoState>) => {
      state.info = { ...action.payload };
      state.open = true;
    },
  },
});

export const { clearLcaInputInfo } = lcaInputInfoSlice.actions;
export const selectLcaInputInfo = (state: RootState): InfoState | null =>
  state.lcaInfo.info || null;
export const selectLcaInputIsOpen = (
  state: RootState,
): SidebarLcaInputInfoState['open'] => state.lcaInfo.open;
export default lcaInputInfoSlice.reducer;
