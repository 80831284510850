import React, { FC } from 'react';
import { Button, Illustration } from '@biocodeio/components';
import { Trans, useTranslation } from 'react-i18next';

import { AUTH0_APP_NAME } from '../../app/constants';
import { useAuth0 } from '@auth0/auth0-react';

interface AuthError {
  message: string;
}
interface AuthProps {
  error?: AuthError | null;
}

const Auth: FC<AuthProps> = ({ error = false }) => {
  const { t, i18n } = useTranslation(['common', 'validation']);
  const { loginWithRedirect, logout } = useAuth0();

  return (
    <div className="Auth">
      <div className="wrap">
        <div className="grid -size:full">
          <div className="Auth-illustrations grid-col:6@l">
            <Illustration name="rawMaterials" />
            <Illustration name="rawMaterials" />
            <Illustration name="rawMaterials" />
          </div>
          <main className="Auth-content grid-col:6@l">
            {!error && (
              <div className="Auth-content-inner">
                <h1 className="Auth-heading">
                  <Trans i18nKey="signInTo" />
                </h1>
                <div className="Auth-text">
                  <p>{t('helloStranger')}</p>
                  <Button
                    className="Auth-button"
                    modifier="secondary"
                    onClick={() =>
                      loginWithRedirect({
                        language: i18n.language,
                        redirect: AUTH0_APP_NAME,
                      })
                    }
                  >
                    {t('continue')}
                  </Button>
                </div>
              </div>
            )}
            {error && (
              <div className="Auth-content-inner">
                <div className="Auth-text">
                  <p>
                    {t('validation:error.authenticationFailed', {
                      reason: error !== true ? error.message : '',
                    })}
                  </p>
                  <Button
                    className="Auth-button"
                    modifier="secondary"
                    onClick={() => logout({ returnTo: window.location.origin })}
                  >
                    {t('logout')}
                  </Button>
                </div>
              </div>
            )}
          </main>
        </div>
      </div>
    </div>
  );
};

export default Auth;
