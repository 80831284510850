import * as R from 'ramda';
import {
  InputParameterConfiguration,
  ParamDependencyConfiguration,
} from './LcaForm.types';
import { LCAFORM_CONSTANTS } from './LcaForm.constants';

export interface FieldInfo {
  id: string;
  i18nId?: string; // used to indicate localization identifierr if that differs from id
}
export interface FormValidationState {
  isValid: boolean;
  hiddenFields: FieldInfo[];
  invalidFields: FieldInfo[];
}

const getValuesForList = (values: any, listName: string) =>
  R.pathOr([], ['lists', listName, 'items'], values);

// Tests if dependencies have accepted values
const isVisible = (
  id: string,
  values: any,
  dependencies: ParamDependencyConfiguration[] | undefined,
): FormValidationState =>
  R.defaultTo([], dependencies)
    .map((dep: ParamDependencyConfiguration) => {
      if (dep.value && !(values.inputs[dep.id] === dep.value)) {
        return { hiddenFields: [{ id }], invalidFields: [], isValid: false };
      }
      if (
        dep.max_value &&
        !(Number.parseFloat(values.inputs[dep.id]) <= dep.max_value)
      ) {
        return { hiddenFields: [{ id }], invalidFields: [], isValid: false };
      }
      if (
        dep.min_value &&
        !(Number.parseFloat(values.inputs[dep.id]) >= dep.min_value)
      ) {
        return { hiddenFields: [{ id }], invalidFields: [], isValid: false };
      }
      return { hiddenFields: [], invalidFields: [], isValid: true };
    })
    .reduce(
      (acc, current) => ({
        ...acc,
        hiddenFields: current.hiddenFields
          ? R.concat(current.hiddenFields, R.defaultTo([], acc.hiddenFields))
          : [],
        invalidFields: [],
        isValid: acc.isValid && current.isValid,
      }),
      {
        hiddenFields: [],
        invalidFields: [],
        isValid: true,
      },
    );

const isRequiredOk = (
  param: InputParameterConfiguration,
  value: any,
): FormValidationState => {
  let isValid = true;
  if (param.required) {
    isValid = !R.isNil(value) && !R.isEmpty(value);
  }
  if (!isValid) {
    return { hiddenFields: [], invalidFields: [{ id: param.id }], isValid };
  }
  return { hiddenFields: [], invalidFields: [], isValid };
};

/**
 * @param param The param object
 * @param value The value to validate
 * @param t A translator (returned by useTranslation)
 * @returns An error message or undefined, if no errors.
 */
const getValueRangeError = (
  param: InputParameterConfiguration,
  value: any,
  t: any,
): string | undefined => {
  let err = undefined;
  if (param.min_value && param.min_value > value) {
    err = `${t('form.warn.low')}. ${t('form.acceptRange')} ${param.min_value}-${
      param.max_value
    }.`;
  }
  if (param.max_value && param.max_value < value) {
    err = `${t('form.warn.high')}. ${t('form.acceptRange')} ${
      param.min_value
    }-${param.max_value}.`;
  }
  return err;
};

const areNormalSectionsValid = (
  values: any,
  configuration: any,
): FormValidationState | null => {
  if (R.isNil(configuration) || R.isNil(values)) {
    return { hiddenFields: [], invalidFields: [], isValid: false };
  }

  if (!R.isNil(configuration) && !R.isNil(values)) {
    // Normal sections
    // Get all parameters for the normal sections
    const inputs = R.pathOr({} as any, ['inputs'], values);

    const normalParameters: InputParameterConfiguration[] = R.pipe(
      R.pathOr([], ['sections']),
      sections =>
        sections.flatMap(section => {
          return R.pathOr([], ['groups'], section);
        }),
      sectionGroups =>
        sectionGroups.flatMap(s => {
          return R.pathOr([], ['parameters'], s);
        }),
    )(configuration);

    let iterated: FormValidationState = {
      hiddenFields: [],
      invalidFields: [],
      isValid: true,
    };
    for (const paramConfig of normalParameters) {
      const value = inputs[paramConfig.id];
      const depsOk = isVisible(
        paramConfig.id,
        values,
        paramConfig.dependencies,
      );
      iterated =
        // Only missing required fields will prevent calculation
        {
          ...isRequiredOk(paramConfig, value),
          hiddenFields: iterated.hiddenFields.concat(depsOk.hiddenFields),
        };

      if (!iterated.isValid) {
        // if field is not hidden by deps, exit early as invalid state
        if (
          iterated.hiddenFields.filter(x => x.id === paramConfig.id).length ===
          0
        ) {
          return iterated;
        }
      }
    }
    return {
      hiddenFields: iterated.hiddenFields,
      invalidFields: [],
      isValid: true,
    };
  }
  return null;
};

const areListSectionsValid = (
  values: any,
  configuration: any,
): FormValidationState => {
  if (R.isNil(configuration) || R.isNil(values)) {
    return { hiddenFields: [], invalidFields: [], isValid: false };
  }
  // Loop list sections
  const listSections = configuration.sections.filter(
    (s: any) => s.type === LCAFORM_CONSTANTS.type_list,
  );
  for (const list of listSections) {
    const listInputs = getValuesForList(values, list.id);
    const paramConfigs = R.pathOr(
      [] as InputParameterConfiguration[],
      ['item_parameters'],
      list,
    );
    for (const input of listInputs) {
      for (const prop in input) {
        const value = input[prop];
        const paramConfig = paramConfigs.find(p => p.id === prop);

        if (!R.isNil(paramConfig)) {
          let validity = { isValid: true };
          const okDep = isVisible(
            paramConfig.id,
            values,
            paramConfig.dependencies,
          );
          if (okDep.isValid) {
            validity =
              // Only missing required fields will prevent calculation
              isRequiredOk(paramConfig, value);
          }
          if (!validity.isValid) {
            return {
              hiddenFields: [],
              invalidFields: [
                { i18nId: paramConfig.id, id: `${(input as any).id}-${prop}` },
              ],
              isValid: false,
            };
          }
        }
      }
    }
  }
  return { hiddenFields: [], invalidFields: [], isValid: true };
};

const isFormValid = (values: any, configuration: any): FormValidationState => {
  const normalSections = areNormalSectionsValid(values, configuration);
  if (normalSections && !normalSections.isValid) {
    return normalSections;
  }
  const listSections = areListSectionsValid(values, configuration);
  if (!listSections.isValid) {
    return listSections;
  }
  return { hiddenFields: [], invalidFields: [], isValid: true };
};

export { isRequiredOk, isVisible, getValueRangeError, isFormValid };
