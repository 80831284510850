export const URL_PATH = {
  notifications: '/hubs/notifications',
  producers: '/producers',
};

export const HUB_EVENTS = {
  calculationFinished: 'CalculationFinished',
  subscribeLcaResult: 'SubscribeLcaResult',
  unsubscribeLcaResult: 'UnsubscribeLcaResult',
};

export const AUTH0_APP_NAME = 'producer-v1';
