import { duration, easing, transDefault } from '@biocodeio/components';

const sidebarTrans = {
  animate: { x: 0 },
  exit: { x: '100%' },
  initial: { x: '100%' },
  transition: transDefault,
};

export { duration, easing, sidebarTrans, transDefault };
