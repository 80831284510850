import React, { FC } from 'react';
import { FieldInfo } from './formValidation';
import { getParameterLocalication } from './formUtils';
import { useTranslation } from 'react-i18next';
import { ValidationInfoProps } from './LcaForm.types';

const ValidationInfo: FC<ValidationInfoProps> = ({
  validationState,
  lcaConfiguration,
}) => {
  const { t, i18n } = useTranslation(['common', 'validation']);

  return (
    <>
      <p>
        <small>
          * {t('form.required')}.
          {!validationState.isValid && (
            <>
              {' ' +
                t('form.invalid') +
                ' ' +
                t('validation:error.formValidationFailed')}
              {validationState.invalidFields &&
                validationState.invalidFields.map((x: FieldInfo, i: any) => {
                  return (
                    <a href={`#${x.id}`} key={i}>
                      {getParameterLocalication(
                        x.i18nId !== undefined ? x.i18nId : x.id,
                        lcaConfiguration,
                        `name_${i18n.language}`,
                      )}
                    </a>
                  );
                })}
            </>
          )}
        </small>
      </p>
    </>
  );
};

export default ValidationInfo;
