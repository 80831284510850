import axios from 'axios';
import { LcaInfo, LcaInfoResponse } from './ProducerLca.types';

const producerLcaClient = {
  calculate: async (lcaId: string): Promise<any> => {
    try {
      return axios.post(`/producerLca/lca/calculate/${lcaId}`).then(resp => {
        return resp.data;
      });
    } catch (e) {
      console.error(e);
      return null;
    }
  },
  copyProducerLca: async (producerLcaId: string): Promise<string> => {
    try {
      return axios.post(`/producerLca/lca/copy/${producerLcaId}`).then(resp => {
        return resp.data;
      });
    } catch (e) {
      console.error(e);
      return '';
    }
  },
  createProducerLca: async (producerSettingsId: string): Promise<string> => {
    try {
      return axios
        .post(`/producerLca/lca/new`, {
          producerLcaSettingsId: producerSettingsId,
        })
        .then(resp => {
          return resp.data;
        });
    } catch (e) {
      console.error(e);
      return '';
    }
  },
  getProducerLca: async (lcaId: string): Promise<any> => {
    try {
      return axios.get(`/producerLca/lca/${lcaId}`).then(resp => {
        return resp.data;
      });
    } catch (e) {
      console.error(e);
      return null;
    }
  },
  getProducerLcaSettings: async (lcaSettingsId: string): Promise<any> => {
    try {
      return axios.get(`/producerLca/settings/${lcaSettingsId}`).then(resp => {
        return resp.data;
      });
    } catch (e) {
      console.error(e);
      return null;
    }
  },
  getProducerLcaTemplates: async (lcaId: string): Promise<LcaInfo[]> => {
    try {
      return axios.get(`/producerLca/lca/${lcaId}/templates`).then(resp => {
        return resp.data;
      });
    } catch (e) {
      console.error(e);
      return [] as LcaInfo[];
    }
  },
  getProducerLcas: async (): Promise<LcaInfoResponse> => {
    try {
      return axios.get('/producerLca/lcas').then(resp => {
        return resp.data;
      });
    } catch (e) {
      console.error(e);
      return {} as LcaInfoResponse;
    }
  },
  refillProducerLcaValues: async (
    lcaId: string,
    originLcaId: string,
  ): Promise<any> => {
    try {
      return axios
        .put(`/producerLca/lca/refill/${lcaId}`, {
          originProducerLcaId: originLcaId,
        })
        .then(resp => {
          return resp.data;
        });
    } catch (e) {
      console.error(e);
      return null;
    }
  },
  updateLcaInputValues: async (lcaId: string, values: any): Promise<any> => {
    try {
      return axios
        .put(`/producerLca/lca/${lcaId}`, { values: JSON.stringify(values) })
        .then(resp => {
          return resp.data;
        });
    } catch (e) {
      console.error(e);
      return null;
    }
  },
};

export default producerLcaClient;
