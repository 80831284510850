export interface LcaInfo {
  modelName: string;
  modified: string;
  modelId: string;
  producerLcaId: string;
  lcaSettingsId: string;
  status: LcaStatus;
  year: number;
}
export type LcaInfoResponse = {
  [key: string]: { [key: string]: LcaInfo[] };
};

export enum LcaResultStatus {
  Available = 'available',
  Completed = 'completed',
}

export enum LcaStatus {
  Completed = 'completed',
  NotStarted = 'notStarted',
  Pending = 'pending',
}
