import * as R from 'ramda';

import React, { FC, useMemo } from 'react';
import { Button, CardBlock, Table, Heading } from '@biocodeio/components';

import { formatDecimal, timeAgo } from '../../lib/utils';
import { LcaResultProps } from './LcaResult.types';
import { useTranslation } from 'react-i18next';

const LcaResult: FC<LcaResultProps> = ({ copyLca, data, resultConfigs }) => {
  const { t, i18n } = useTranslation(['common', 'units']);
  const isLoading = !data || !data.hasResults;
  const modified =
    data.resultDate !== undefined
      ? timeAgo(data.resultDate, i18n.language)
      : '';

  const memoColumns = useMemo(
    () => [
      {
        accessor: 'name',
        header: t('lcaResult.stage'),
      },
      {
        Cell: function Cell(rowData: any) {
          const { value } = rowData.row.original;

          return (
            <>
              {value}
              {t('units:percent')}
            </>
          );
        },
        accessor: 'value',
        header: t('lcaResult.percentShare'),
      },
    ],
    [t],
  );

  const ResultBlocks = ({ data }: any) => {
    const CardBlockComponent = ({ lcaResult }: any) => {
      const resultDetails = JSON.parse(
        R.pathOr('', ['detailsJson'], lcaResult),
      );
      const resConfig = R.pathOr({}, [lcaResult.rmExternalId], resultConfigs);
      const shareConfigs = R.pathOr({}, ['shareNames'], resConfig);
      const co2eShares = R.pathOr([], ['co2eShares'], resultDetails).map(
        (item: any) => {
          const name = R.pathOr('', [item.id], shareConfigs);
          return {
            name,
            value: formatDecimal(item.value, 1),
          };
        },
      );
      const memoData = useMemo(() => co2eShares, [co2eShares]);

      return (
        <div className="grid-col:6@m" key={lcaResult.externalId}>
          <CardBlock
            heading={lcaResult.rmName}
            headingResult={formatDecimal(lcaResult.resultValue, 2)}
            headingResultUnit={
              t('units:kgCO2e') + '\n /' + t(`units:${lcaResult.unit}`)
            }
            size="small"
          >
            <Table
              alignLastColumnRight
              columns={memoColumns}
              columnsWidth={[2, 1.5]}
              data={memoData}
            />
            <Heading
              className="marginBottom--m"
              subtitle={`${t('lcaResult.totalAmount')}${': '}${formatDecimal(
                lcaResult.totalAmount,
                0,
              )}${' '}${t(`units:${lcaResult.unit}`)}`}
              subtitleColor="strong"
            />
          </CardBlock>
        </div>
      );
    };

    return (
      <>
        {data
          .filter((r: any) => r.totalAmount > 0 && r.resultValue > 0)
          .map((lcaResult: any) => {
            return (
              <CardBlockComponent
                key={lcaResult.externalId}
                lcaResult={lcaResult}
              />
            );
          })}
      </>
    );
  };

  if (isLoading) {
    return <></>;
  }

  return (
    <>
      <div className="grid -align:center marginBottom--m">
        <div className="grid-col:6@s">
          <h2 className="noMargin">{t('lcaResult.heading')}</h2>
        </div>
        <div className="grid-col:6@s">
          <p className="noMargin textAlignRight@s">
            <small>
              {t('lcaResult.resultDate')} {modified}.
            </small>
          </p>
        </div>
      </div>
      <div className="grid -rowGap marginBottom--l">
        <ResultBlocks data={data.results} />
      </div>
      <Button
        onClick={() => {
          if (window.confirm(t('lcaResult.confirmRedo'))) {
            copyLca();
          }
        }}
      >
        {t('lcaResult.redo')}
      </Button>
    </>
  );
};

export default LcaResult;
