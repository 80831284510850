import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './store';

export const userProfileClaim = 'http://biocode/userProfile';

export enum UserProfile {
  admin = 'admin',
  producer = 'producer',
}
interface AuthState {
  token: string | null;
  status: AuthStatus;
  profile?: UserProfile;
}

export enum AuthStatus {
  Pending = 'pending',
  Authenticated = 'authenticated',
  Unauthenticated = 'unauthenticated',
}

const initialState: AuthState = {
  status: AuthStatus.Unauthenticated,
  token: null,
};

const authSlice = createSlice({
  initialState,
  name: 'auth',
  reducers: {
    setAuthStatus: (state, action: PayloadAction<AuthStatus>) => {
      state.status = action.payload;
    },
    setToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
    },
    setUserProfile: (state, action: PayloadAction<UserProfile>) => {
      state.profile = action.payload;
    },
  },
});

export const { setToken, setAuthStatus, setUserProfile } = authSlice.actions;
export const selectToken = (state: RootState): string | null =>
  state.auth.token;
export const selectAuthStatus = (state: RootState): AuthStatus =>
  state.auth.status;
export const selectUserProfile = (state: RootState): UserProfile | undefined =>
  state.auth.profile;

export default authSlice.reducer;
