export interface ProducerLca {
  inputs: string;
  hasResults: boolean;
  calculationStatus: CalculationStatus;
  resultDate?: string;
  results: ProduceResult[];
}

export interface ProduceResult {
  externalId: string;
  totalAmount: number;
  resultValue: number;
  unit: string;
  rmExternalId: string;
  rmName: string;
  detailsJson: string;
}

export enum CalculationStatus {
  none = 'none',
  requested = 'requested',
  finished = 'finished',
}

export interface LcaResultProps {
  copyLca(): void;
  data: ProducerLca;
  resultConfigs: any;
}
