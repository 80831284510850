import * as R from 'ramda';
import LcaResult from '../../components/LcaResult';
import producerLcaClient from '../../components/ProducerLca/producerLcaClient';
import React, { useCallback, useState, FC, useEffect, useRef } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import {
  Button,
  CardBlock,
  Overlay,
  Section,
  View,
} from '@biocodeio/components';
import {
  CalculationStatus,
  ProducerLca,
} from '../../components/LcaResult/LcaResult.types';
import { getDefaultsForNormalSections } from '../../components/LcaForm/formUtils';
import { HUB_EVENTS, URL_PATH } from '../../app/constants';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import {
  isFormValid,
  CopyValues,
  ListSection,
  NormalSection,
  ValidationInfo,
} from '../../components/LcaForm';
import { LCAFORM_CONSTANTS } from '../../components/LcaForm/LcaForm.constants';
import {
  ModelConfiguration,
  SectionConfiguration,
} from '../../components/LcaForm/LcaForm.types';
import { default as q } from '../../app/queryKeys';
import {
  SidebarLcaInputInfo,
  selectLcaInputIsOpen,
} from '../../components/Sidebar';
import { useHistory } from 'react-router-dom';
import { useImmer } from 'use-immer';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const LcaForm: FC<any> = ({ match }) => {
  // Parameters
  const settingsId = match.params.id;
  const lcaId = match.params.lcaId;

  // State variables (including state, redux, refs)
  const [values, setValues] = useImmer({ inputs: {}, lists: {} });
  const [results, setResults] = useImmer({} as ProducerLca);
  const [locked, setLocked] = useImmer(true);
  const [calculationRequested, setCalculationRequested] = useImmer(false);
  const isInfoOpen = useSelector(selectLcaInputIsOpen);
  const didMount = useRef(false);
  const pauseUpdate = useRef(false);
  const [connection, setConnection] = useState<null | HubConnection>(null);

  // Other "stores"
  const { t, i18n } = useTranslation(['common', 'validation']);
  const history = useHistory();
  const queryClient = useQueryClient();

  // -----------------------------------------------------------------
  // Mutations
  // -----------------------------------------------------------------

  /**
   * Save the current value collection
   */
  const updateValues = useMutation((updatedValues: any): Promise<any> => {
    if (locked) return Promise.resolve();
    return producerLcaClient.updateLcaInputValues(lcaId, updatedValues);
  });

  /**
   * Start calculation.
   */
  const calculation = useMutation(
    async (): Promise<any> => {
      if (locked) return Promise.resolve();
      pauseUpdate.current = true;
      return await producerLcaClient.calculate(lcaId);
    },
    {
      onSuccess: () => {
        refetchData();
        pauseUpdate.current = false;
      },
    },
  );

  /**
   * Copy the current lca.
   */
  const copyLca = useMutation(
    async () => {
      return await producerLcaClient.copyProducerLca(lcaId);
    },
    {
      onSuccess: data => {
        pauseUpdate.current = false;
        history.push(`/entry/${settingsId}/${data}`);
      },
    },
  );

  /**
   * Refill values for the current lca
   */
  const refillValues = useMutation(
    async (originLcaId: string) => {
      // Before refilling: pause saving of values
      pauseUpdate.current = true;
      queryClient.cancelQueries([q.producer.lcaData, lcaId]);

      return await producerLcaClient.refillProducerLcaValues(
        lcaId,
        originLcaId,
      );
    },
    {
      onSettled: () => {
        // After updating: activate saving of values
        pauseUpdate.current = false;
        // Refetch the form data with the refilled values
        queryClient.cancelQueries(q.producer.lcaData);
        return queryClient.invalidateQueries(q.producer.lcaData, {
          refetchActive: true,
        });
      },
    },
  );

  // -----------------------------------------------------------------
  // Queries
  // -----------------------------------------------------------------

  /**
   * Query object for the lca data.
   * Sets the following state variables: values, results, calculation requested, locked.
   */
  const lcaData = useQuery<any>(
    [q.producer.lcaData, lcaId],
    async (): Promise<any> => await producerLcaClient.getProducerLca(lcaId),
    {
      onSuccess: (data: any) => {
        const userInputs = JSON.parse(R.pathOr('{}', ['inputs'], data));
        setValues(oldValues => ({ ...oldValues, ...userInputs }));
        setResults(oldData => data);
        setCalculationRequested(
          oldState => data.calculationStatus === CalculationStatus.requested,
        );
        setLocked(
          oldState =>
            data.calculationStatus === CalculationStatus.finished ||
            data.calculationStatus === CalculationStatus.requested,
        );
      },
    },
  );

  /**
   * Query object for the lca settings.
   * Returs the data object split into:
   * {configuration, response, resultConfigs}
   * Side effects: Sets default values from the settings that the user hasn't touched, i.e.
   * the parameter does not even exist in the values collection.
   */
  const settingsQuery = useQuery<any>(
    [q.producer.lcaForm, settingsId],
    async (): Promise<any> => {
      const data = await producerLcaClient.getProducerLcaSettings(settingsId);
      const configuration = data && JSON.parse(data.configuration);
      const resultConfigs = data && data.resultConfigs;
      return { configuration, response: data, resultConfigs };
    },
    {
      onSuccess: (data: any) => {
        const lcaConfiguration: ModelConfiguration = data && data.configuration;
        const initialValues = getDefaultsForNormalSections(lcaConfiguration);
        setValues(oldValues => ({
          inputs: { ...initialValues, ...oldValues.inputs },
          lists: values.lists,
        }));
      },
    },
  );

  // -----------------------------------------------------------------
  // Query helper functions.
  // -----------------------------------------------------------------

  const onRefill = (originLcaId: string) => {
    refillValues.mutate(originLcaId);
  };

  const refetchData = useCallback(() => {
    // Invalidate all lca data, regardless of lcaId. This page can
    // use many lcaIds over time.
    return queryClient.invalidateQueries([q.producer.lcaData], {
      refetchActive: true,
    });
  }, [queryClient]);

  // -----------------------------------------------------------------
  // Render cycle effects
  // -----------------------------------------------------------------

  // Save all values whenever the values collection changes.
  useEffect(() => {
    // Do not update the first time the page loads.
    // At startup, the values collection is set up by the page code, not by the user.
    if (!didMount.current) {
      didMount.current = true;
      return;
    }

    // Check if updates are allowed. Updates usually allowed all the time, but
    // not while some server-side operation is going on, such as copying values
    // or performing the calculation.
    if (!pauseUpdate.current) {
      updateValues.mutate(values);
    }
  }, [values]); // eslint-disable-line react-hooks/exhaustive-deps

  // Set up a SignalR websocket connection for result notifications
  useEffect(() => {
    if (connection) return;
    const host = process.env.REACT_APP_PRODUCER_API_HOST || '';
    const url = `https://${host}${URL_PATH.notifications}`;

    const newConnection = new HubConnectionBuilder()
      .withUrl(url)
      .withAutomaticReconnect()
      .build();

    newConnection.start().then(() => setConnection(newConnection));
  });

  // Set up the result notification listeners
  useEffect(() => {
    // Subscribe to this lca id's results, if we have a connection.
    // Note: we'll subscribe even to finished calculations.
    // In the future, prevent subscribing to these.
    if (connection) {
      //console.log(`subscribetään: ${lcaId}`);
      // Subscribe to this specific lca id
      connection.send(HUB_EVENTS.subscribeLcaResult, lcaId);

      // Setup a listener for results finished.
      // We might have a listener from before (e.g. from a previous lcaid), so remove it if necessary.
      // One single listener is enough.
      connection.off(HUB_EVENTS.calculationFinished);
      connection.on(HUB_EVENTS.calculationFinished, (success: boolean) => {
        //console.log('nyt pitäisi päivittää!');
        refetchData();
      });

      // When the lcaId changes, unsubscribe to notifications for the previous lcaId.
      return () => {
        if (connection) {
          //console.log('Unsubscribing to lcaId', lcaId);
          connection.send(HUB_EVENTS.unsubscribeLcaResult, lcaId);
        }
      };
    }

    // This should be run exactly once for each lcaId. Do not include refetchData as a dependency.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connection, lcaId]);

  // -----------------------------------------------------------------
  // Render.
  // -----------------------------------------------------------------

  if (
    settingsQuery.isLoading ||
    lcaData.isLoading ||
    calculation.isLoading ||
    copyLca.isLoading ||
    refillValues.isLoading
  )
    return <View isLoading={true} modifier="lcaForm" />;

  const lcaConfiguration: ModelConfiguration =
    settingsQuery.data && settingsQuery.data.configuration;
  const resultConfigs = settingsQuery.data && settingsQuery.data.resultConfigs;

  const formSections = R.pathOr([], ['sections'], lcaConfiguration) as any[];

  /*
    TODO Now the sections (normal and list) will rerender every time the
    value set changes. Instead, pass only the values the section needs? memoize that?
    Move to a separate task?
  */
  const sections = formSections.map((section: SectionConfiguration) => {
    if (section.type === LCAFORM_CONSTANTS.type_normal) {
      return (
        <NormalSection
          key={section.id}
          locked={locked}
          section={section}
          updateInputValue={(updatedValue: any) => {
            setValues(oldValues => ({
              ...oldValues,
              inputs: {
                ...oldValues.inputs,
                [updatedValue.name]: updatedValue.value,
              },
            }));
          }}
          values={values}
        />
      );
    } else if (section.type === LCAFORM_CONSTANTS.type_list) {
      return (
        <ListSection
          key={section.id}
          locked={locked}
          section={section}
          setValues={setValues}
          values={values}
        />
      );
    } else {
      return <></>;
    }
  });

  const formValid = isFormValid(values, lcaConfiguration);

  const calculateButton = (
    <>
      <Button
        disabled={locked || !formValid.isValid}
        isLoading={calculation.isLoading}
        isSuccess={calculation.isSuccess || locked}
        language={i18n.language}
        modifier="primary"
        onClick={() => {
          calculation.mutate();
        }}
        successText={t('form.success')}
      >
        {t('calculate')}
      </Button>
    </>
  );

  const settings = R.pathOr(null, ['response'], settingsQuery.data) as any;
  return (
    <>
      <View
        isLoading={settingsQuery.isLoading || lcaData.isLoading}
        modifier="lcaForm"
      >
        <Section border wrapSize="xSmall">
          {settings && lcaData.data && (
            <h1>{`${lcaData.data.producerName}: ${settings.modelName}, ${settings.lcaYear}`}</h1>
          )}
        </Section>
        {calculationRequested && (
          <Section border wrapSize="xSmall">
            <CardBlock heading={t('lcaResult.heading')}>
              <span>{t('form.calculationRequested')}</span>
            </CardBlock>
          </Section>
        )}
        {results.hasResults && (
          <Section border wrapSize="xSmall">
            <LcaResult
              copyLca={() => {
                copyLca.mutate();
                calculation.reset();
              }}
              data={results}
              resultConfigs={resultConfigs}
            />
          </Section>
        )}

        {settings && lcaData.data && (
          <CopyValues
            enabled={!locked}
            modelId={settings.modelId}
            onChange={onRefill}
            producerLcaId={lcaId}
          />
        )}
        <form noValidate={true}>
          {sections}
          <Section wrapSize="xSmall">
            {calculateButton}
            <ValidationInfo
              lcaConfiguration={lcaConfiguration}
              validationState={formValid}
            />
          </Section>
        </form>
      </View>
      <AnimatePresence>
        {isInfoOpen && <SidebarLcaInputInfo key="SidebarLcaInputInfo" />}
        {isInfoOpen && <Overlay key="Overlay" motion={motion} />}
      </AnimatePresence>
    </>
  );
};

export default LcaForm;
