import producerLcaClient from '../ProducerLca/producerLcaClient';
import React, { useState } from 'react';
import { CopyValuesProps } from './LcaForm.types';
import { LcaInfo } from '../ProducerLca/ProducerLca.types';
import { default as q } from '../../app/queryKeys';
import { Section, Select } from '@biocodeio/components';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';

const CopyValues: React.FC<CopyValuesProps> = ({
  enabled,
  modelId,
  onChange,
  producerLcaId,
}) => {
  const { t } = useTranslation(['common']);
  const [selectedOption, setSelectedOption] = useState(null);

  const { data, isLoading } = useQuery<any[]>(
    [q.producer.lcaTemplates, producerLcaId],
    async (): Promise<any[]> => {
      return await producerLcaClient
        .getProducerLcaTemplates(producerLcaId)
        .then(resp => {
          const lcas = resp as LcaInfo[];
          const lcasForSameModel = lcas.filter(x => x.modelId === modelId);
          const lcasForOtherModels = lcas.filter(x => x.modelId !== modelId);

          const producerLcaModelName = lcasForSameModel[0]?.modelName || '';

          const groupedOptions = [
            {
              label: producerLcaModelName,
              options: lcasForSameModel.map(x => {
                return {
                  label: x.modelName + ' - ' + x.year,
                  value: x.producerLcaId,
                };
              }),
            },
            {
              label: t('form.otherProducts'),
              options: lcasForOtherModels.map(x => {
                return {
                  label: x.modelName + ' - ' + x.year,
                  value: x.producerLcaId,
                };
              }),
            },
          ];

          return groupedOptions;
        });
    },
  );

  const id = 'select_refillValues';

  const show =
    enabled &&
    data &&
    data.length > 0 &&
    data.flatMap(x => x.options).length > 0;

  return show ? (
    <Section border wrapSize="xSmall">
      <div className="grid-col grid-col:6@m" key={id}>
        <Select
          id={id}
          isLoading={isLoading}
          label={t('form.copyValuesFromOtherForm')}
          modifier="button"
          onChange={(selected: any) => {
            setSelectedOption(null);
            if (selected?.value) {
              // Check that something really was selected
              if (window.confirm(t('form.confirmCopyValues'))) {
                onChange(selected?.value);
              }
            }
          }}
          options={data}
          placeholder={t('form.copyValuesShort')}
          value={selectedOption}
        />
      </div>
    </Section>
  ) : (
    <></>
  );
};

export default CopyValues;
