import axios from 'axios';

const host = process.env.REACT_APP_PRODUCER_API_HOST || '';

const setAxiosDefaults = (token: string, language: string): void => {
  axios.defaults.baseURL = `https://${host}`;
  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  axios.defaults.headers.common['Accept-Language'] = language;
};

export { setAxiosDefaults };
